import React, { useState, useEffect } from "react";
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/cerberus.gif";
import Layout from "./Layout";
import "../App.css";
import Menu from "./menu";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import Notifications from "./notifications";

  function Mint() {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorMessageVisible, setErrorMessageVisible] = useState(true);
  
    console.log(data);

    const mint = (_account, _name) => {
      setLoading(true);
      blockchain.xenomorph.methods.mint(_name).send({from: _account, value: blockchain.web3.utils.toWei("0.080032012805122", "ether"),})
        .once("error", (err) => {
          setLoading(false);
          console.log(err);
        })
        .then((receipt) => {
          setLoading(false);
          if (!receipt.status) {
            // check receipt.events for error event
            setErrorMessage({errorMessage});
            setErrorMessageVisible(true);
        } else {
            dispatch(fetchData(blockchain.account));
        }
        });
    };

    useEffect (() => {
      dispatch(connect());  
    }, []);

    useEffect(() => {
      if (blockchain.account !== "" && blockchain.xenomorph != null) {
        dispatch(fetchData(blockchain.account));
      }
    }, [blockchain.account, blockchain.xenomorph]);

  return (
      <s.Screen image={cerberus}>
      <Layout />
      <div className={"division1 transmint"}   />
      <div className="translation" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <img src={require("../assets/images/morphs.gif")} className="portraitMNT" />          
          <div style={{padding: "2px"}}>            
              <input className={"inputform"} style={{color: "white", border: "3px solid rgb(123, 106, 99)"}}
                type="text"
                placeholder="Name Your Xenomorph"
                value={name}
                onChange={(e) => setName(e.target.value)}
                alt="Name Your Xenomorph"
              />
          </div>
            <button className={"buttongold textstyle"}
            disabled={loading ? 1 : 0}
            onClick={(e) => {
              e.preventDefault();
              mint(blockchain.account, name);
            }}
          >
            Spawn
          </button>
      </div>

      <div className={"division3 transomint"} />
      
      <Menu />
      <Notifications />
      </s.Screen>
    );
}

export default Mint;