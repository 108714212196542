import React, { useEffect, useState } from "react";
import "../App.css";
import { ethers } from "ethers";
import abi from "../abi/abi.json";
import { Link } from "react-router-dom";

function Layout() {
  const [account, setAccount] = useState("");
  const [provider, setProvider] = useState(null);
  const [balancer, setBalancer] = useState("");
  const contractAddress = "0x1cC4D981e897A3D2E7785093A648c0a75fAd0453";

  const initConnection = async () => {
    if (typeof window.ethereum !== "undefined") {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const tempProvider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(tempProvider);
      setAccount(accounts[0]);
    } else {
      console.log("Please install Metamask.");
    }
  };

  const bal = async () => {
    const contractERC = new ethers.Contract(
      contractAddress,
      abi,
      provider
    );
    const tempBal = await contractERC.balanceOf(account);
    setBalancer(tempBal.toString());
  };

  useEffect (() => {
  initConnection();  
  }, []);

  return ( 
      <div className="header">
          <Link to="/" className={"textstyle"}><img src={require(`./xenbox_w_txt.png`)} style={{height: "20px"}}/></Link>              
          {balancer == "" ?        
            <button className='button0' onClick={bal}>Load Balance</button>
               : (
            <p style={{color: "white"}}>{(Number(balancer)/10**18).toFixed()} $XEN Available</p>)} 
          {account == "" ? 
            <button  onClick={initConnection} className={"button1"}>
              Connect Wallet
            </button>
            : (
              <p>...{account.substring(account.length - 5)} </p> )}
        </div> 
  );
}

export default Layout;